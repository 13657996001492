import React from "react";
import { useSelector } from "react-redux";
import { history } from "utility/helper/history";
import * as Icon from "react-feather";
import { NavItem, NavLink } from "reactstrap";
import { arrowLeft } from "assets/icons/svgIcons";
import { useLocation, useParams } from "react-router-dom";
import { getPageTitle } from "./data";
import { FormattedMessage } from "react-intl";


function NavbarBookmarks(props) {
  const { pathname } = useLocation();
  const params = useParams();
  const { sidebarVisibility } = props;
  const currentPageName = getPageTitle({ pathName: pathname, query: params });
  
  const acPath = pathname === "/asset-acceptance/list";

  const { user } = useSelector((state) => ({
    user: state?.auth?.login?.data,
  }));

  return (
    <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
      <ul className="navbar-nav  mr-auto align-items-center">
        <NavItem className="mobile-menu  d-xl-none navbar--menuIcon">
          <NavLink className="nav-menu-main menu-toggle hidden-xs is-active" onClick={sidebarVisibility}>
            <Icon.Menu className="ficon" color="#2a347b" />
          </NavLink>
        </NavItem>
        {!acPath &&(
          <NavItem className="mobile-menu">
            <NavLink className="nav-menu-main navbar--backBtn">
              <figure onClick={() => history.goBack()}>{arrowLeft}</figure>
            </NavLink>
          </NavItem>
        )}
        {(!params?.workOrderId && currentPageName && (
          <NavItem className="mobile-menu">
            <NavLink className="nav-menu-main navbar--title">
              {/* {currentPageName === "Work Order - Details" ? ( */}
              {<FormattedMessage id={currentPageName} defaultMessage={currentPageName} />}
            </NavLink>
          </NavItem>
        )) || <div />}
      </ul>
    </div>
  );
}

export default NavbarBookmarks;
